import { Link } from '@remix-run/react'
import { Avatar, Tooltip } from '~/components/ui'
import { ListingBroker, TransactionBroker, User } from '~/models'
import { cn, fmtPhoneNumber, getBrokerMemberships } from '~/utils'

export default function ListingPreviewBrokers({
  className,
  brokers,
}: {
  className?: string
  brokers: ((TransactionBroker & { meta?: any }) | ListingBroker | User)[]
}) {
  if (brokers.length === 0) {
    return null
  }

  return (
    <div className={cn(className, 'flex -space-x-1 overflow-hidden p-1')}>
      {brokers.map((broker) => (
        <Tooltip.Provider delayDuration={200} key={broker._id}>
          <Tooltip>
            <Tooltip.Trigger asChild>
              <Link
                to={`/brokers/${broker.slug}`}
                target="_blank"
                onClick={(e) => e.stopPropagation()}>
                <Avatar className="inline-block h-10 w-10 rounded-full bg-background ring-2 ring-muted hover:ring-primary">
                  {broker.meta?.photo && (
                    <Avatar.Image
                      src={broker.meta!.photo!.url}
                      alt={`${broker.firstName} ${broker.lastName}`}
                      loading="lazy"
                    />
                  )}
                  <Avatar.Fallback className="rounded-none bg-transparent">
                    {[broker.firstName.slice(0, 1), broker.lastName.slice(0, 1)]
                      .join('')
                      .toUpperCase()}
                  </Avatar.Fallback>
                </Avatar>
              </Link>
            </Tooltip.Trigger>
            <Tooltip.Content>
              <p className="text-semibold">
                {`${broker.firstName} ${broker.lastName}`}{' '}
                {broker.meta?.memberships && (
                  <span className="ml-1 text-xs text-muted-foreground">
                    {getBrokerMemberships(broker)}
                  </span>
                )}
              </p>
              <p>{broker.email}</p>
              {broker.meta?.office && (
                <p>
                  <span className="text-semibold">O:</span>{' '}
                  {fmtPhoneNumber(broker.meta!.office)}
                </p>
              )}
              {broker.meta?.mobile && (
                <p>
                  <span className="text-semibold">M:</span>{' '}
                  {fmtPhoneNumber(broker.meta!.mobile)}
                </p>
              )}
            </Tooltip.Content>
          </Tooltip>
        </Tooltip.Provider>
      ))}
    </div>
  )
}
